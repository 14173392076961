'use client'

import dynamic from 'next/dynamic'
import { useTheme } from '@mui/material/styles'
import { Grid } from '@mui/material'

// Types
import type { PageProps } from '@/types/app/pages'

// Components
const TitleAtom = dynamic(() => import('@/components/atoms/title'))

const HomePage = (props: PageProps) => {
	// Props
	const { params } = props
	const { lng } = params

	// Variables
	const theme = useTheme()

	return (
		<Grid px={2} sx={{ zIndex: theme.zIndex.appBar + 1 }}>
			<TitleAtom lng={lng} title="common:title.dashboard" />
		</Grid>
	)
}

export default HomePage
